import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  IconButton,
  Slider,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getProgressColor } from "../../../../helpers/colorHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../../styled/generic/HorizBox";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import DocViewer from "../../../styled/generic/DocViewer";
import Api from "../../../../helpers/Api";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import SingleUserWithCaptionTableCell from "../../../styled/generic/SingleUserWithCaptionTableCell";
import TaskProgressUpdateDrawer from "./TaskProgressUpdateDrawer";
import { DeleteOutline, Edit, EditOutlined } from "@mui/icons-material";
import AddTaskDialog from "./AddTaskDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import config from '../../../../config/index'
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";


const useStyles = makeStyles((theme) => ({
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
}));


const TaskCard = ({
  fullTaskView = false,
  task,
  tasks,
  setTask,
  setTasks,
  setEditUnits,
  setEditBlocks,
  disableBorder = false,
  disableElevation = false,
}) => {
  console.log('tasktask-', task);
  const classes = useStyles();
  const history = useHistory();
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const [showUpdateTaskDialog, setShowUpdateTaskDialog] = useState(false);
  const [taskToUpdate, setTaskToUpdate] = useState(null);
  const [workDoneToday, setWorkDoneToday] = useState(0);
  const [workLeft, setWorkLeft] = useState(0);
  const [comment, setComment] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);

  const date = new Date(task?.createdAt);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  var isDev = config?.mode == 'dev'

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const deleteTask = async (task) => {
    try {
      const { data } = await Api.post(`/wbs/delete/`, {
        taskIds: [task._id],
      });

      setTasks((prev) => {
        return prev.filter((item) => item._id !== task._id);
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Task deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while deleting task",
        },
      });
    }
  };

  const renderDateInfo = () => {
    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      return date instanceof Date && !isNaN(date) ? 
        date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: '2-digit' }) : null;
    };

    const plannedStart = formatDate(task?.plannedStart);
    const plannedFinish = formatDate(task?.plannedFinish);
    const startDate = formatDate(task?.startDate);
    const endDate = formatDate(task?.endDate);

    const dateContainerStyle = {
      display: 'flex',
      gap: '12px',
      marginTop: '8px',
      fontSize: '0.8rem',
      flexWrap: 'wrap'
    };

    const dateItemStyle = {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    };

    const labelStyle = {
      color: '#666',
      fontWeight: 'bold'
    };

    const valueStyle = {
      color: '#333',
    };

    if (task?.progress === 0) {
      return (
        <Box sx={dateContainerStyle}>
          {plannedStart && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>PS:</span>
              <span style={valueStyle}>{plannedStart}</span>
            </Box>
          )}
          {plannedFinish && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>PF:</span>
              <span style={valueStyle}>{plannedFinish}</span>
            </Box>
          )}
        </Box>
      );
    } else if (task?.progress > 0 && task?.progress < 100) {
      const expectedCompletion = task?.startDate && task?.duration ? 
        formatDate(new Date(new Date(task.startDate).getTime() + task.duration * 24 * 60 * 60 * 1000)) : 
        null;

      return (
        <Box sx={dateContainerStyle}>
          {plannedStart && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>PS:</span>
              <span style={valueStyle}>{plannedStart}</span>
            </Box>
          )}
          {plannedFinish && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>PF:</span>
              <span style={valueStyle}>{plannedFinish}</span>
            </Box>
          )}
          {startDate && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>S:</span>
              <span style={valueStyle}>{startDate}</span>
            </Box>
          )}
          {expectedCompletion && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>E:</span>
              <span style={valueStyle}>{expectedCompletion}</span>
            </Box>
          )}
        </Box>
      );
    } else if (task?.progress === 100) {
      return (
        <Box sx={dateContainerStyle}>
          {plannedStart && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>PS:</span>
              <span style={valueStyle}>{plannedStart}</span>
            </Box>
          )}
          {plannedFinish && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>PF:</span>
              <span style={valueStyle}>{plannedFinish}</span>
            </Box>
          )}
          {startDate && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>S:</span>
              <span style={valueStyle}>{startDate}</span>
            </Box>
          )}
          {endDate && (
            <Box sx={dateItemStyle}>
              <span style={labelStyle}>E:</span>
              <span style={valueStyle}>{endDate}</span>
            </Box>
          )}
        </Box>
      );
    }
    return null;
  };

  const renderBlocksAndUnits = () => {
    const renderItems = (items, label) => {
      if (!items || items.length === 0) return null;

      const displayedItems = items.slice(0, 4);
      const remainingCount = items.length - displayedItems.length;

      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', mt: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {label}:
          </Typography>
          {displayedItems.map((item) => (
            <Box
              key={item._id}
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                padding: '2px 6px',
                fontSize: '0.8rem',
              }}
            >
              {item.name}
            </Box>
          ))}
          {remainingCount > 0 && (
            <Box
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                padding: '2px 6px',
                fontSize: '0.8rem',
              }}
            >
              +{remainingCount}
            </Box>
          )}
        </Box>
      );
    };

    return (
      <>
        {renderItems(task?.projectBlocks, 'Blocks')}
        {renderItems(task?.units, 'Units')}
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          minWidth: "20rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "white",
          p: 2,
          borderRadius: "12px",
          border: disableBorder ? "none" : "1px solid rgba(0,0,0,0.10)",
          boxShadow: disableElevation ? "none" : "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
          "&:hover": {
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.20)",
          }
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              paddingBottom: "20px",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <Avatar
                alt={task?.creator?.parent?.displayName}
                src={task?.creator?.parent?.displayPicture?.url}
                sx={{ width: 42, height: 42 }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {task?.creator?.parent?.displayName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "semibold", fontSize: "0.9rem" }}
                >
                  {formattedDate}
                </Typography>
              </Box>
            </Box>

            <div>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <Button
                    variant="text"
                  >
                    <MoreVertIcon />
                  </Button>
                }
                showContent={<div className={classes.optionsCont} >
                  <div
                    className={classes.typeSingleOptionSty}
                    onClick={() => {
                      setTaskToUpdate(task);
                      setShowEditTaskDialog(true);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className={classes.typeSingleOptionSty}
                    onClick={() => {
                      deleteTask(task);
                    }}
                  >
                    Delete
                  </div>
                </div>}
              />
            </div>
          </Box>
          <Box
            onClick={() => {
              if (isDev) {
                history.push(`/planning/task/${task?._id}`);
              } else if (config.mode == 'prod') {
                let link = `https://planning.reallist.ai/planning/task/${task?._id}`
                window.open(link, "_self");
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="body1"
            >
              {!fullTaskView ? (
                <>
                  {String(task?.taskName).toUpperCase() ? (
                    String(task?.taskName).toUpperCase().length > 80 ? (
                      <>
                        {String(task?.taskName).toUpperCase().slice(0, 80)}.....
                      </>
                    ) : (
                      String(task?.taskName).toUpperCase()
                    )
                  ) : (
                    "Untitled"
                  )}
                </>
              ) : String(task?.taskName).toUpperCase() ? (
                String(task?.taskName).toUpperCase()
              ) : (
                "Untitled"
              )}
            </Typography>


            {task?.assigned && task.assigned.length > 0 && (
              <Box sx={{ display: "flex", justifyContent: "start", mt: 1 }}>
                Assigned
                <AvatarGroup
                  sx={{
                    "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 15 },
                  }}
                  max={10}
                >
                  {task.assigned.map(({ parent }) => (
                    <BootstrapTooltip placement="top" title={parent?.displayName} key={parent?._id}>
                      <Avatar
                        alt={parent?.displayName}
                        sx={{ width: 26, height: 26 }}
                        src={
                          parent?.displayPicture?.thumbUrl ||
                          parent?.displayPicture?.url
                        }
                      />
                    </BootstrapTooltip>
                  ))}
                </AvatarGroup>
              </Box>
            )}
            <Box
              sx={{
                paddingBottom: "20px",
                display: "flex",
                flexDirection: 'column',
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {renderDateInfo()}
              </Box>
            </Box>
            <HorizBox sx={{ 
              overflowX: 'auto', 
              flexWrap: 'nowrap',
              '&::-webkit-scrollbar': { height: '6px' },
              '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
              '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '3px' }
            }}>
              {[...(task?.labels ?? []), ...(task?.structures ?? [])]
                .filter(Boolean)
                .filter((item, index, self) => 
                  item?._id && index === self.findIndex((t) => t?._id === item?._id)
                )
                .slice(0, 2)
                .map((item) => (
                  <div
                    key={item?._id}
                    style={{
                      backgroundColor: (item?.colorCode ?? "#666") + "33",
                      color: item?.colorCode ?? "#666",
                      padding: "4px 8px",
                      borderRadius: "10px",
                      fontSize: '0.875rem',
                      whiteSpace: 'nowrap',
                      flexShrink: 0,
                      marginRight: '8px'
                    }}
                  >
                    {item?.name ?? "Unnamed"}
                  </div>
                ))}
              {([...(task?.labels ?? []), ...(task?.structures ?? [])].length > 2) && (
                <div>+{[...(task?.labels ?? []), ...(task?.structures ?? [])].length - 2}</div>
              )}
            </HorizBox>
            {renderBlocksAndUnits()}
          </Box>

          <Box sx={{ 
              mt: 1, 
              mb: 1,
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px',
              maxHeight: '3.6em', // Allows for approximately 3 lines
              overflowY: 'auto',
              '&::-webkit-scrollbar': { width: '4px' },
              '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
              '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '2px' }
            }}>
              {Array.isArray(task?.ancestors) && task.ancestors
                .filter(ancestor => ancestor && typeof ancestor === 'object' && ancestor._id)
                .map((ancestor, index, filteredAncestors) => (
                <Box 
                  key={ancestor._id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                    padding: '2px 6px',
                  }}
                >
                  <Typography 
                    sx={{ 
                      fontSize: '0.7rem', 
                      color: '#666',
                      lineHeight: '1.2',
                    }}
                  >
                    {ancestor.taskName?.length > 20 
                      ? `${ancestor.taskName.substring(0, 20)}...` 
                      : ancestor.taskName || 'Unnamed'}
                  </Typography>
                  {index < filteredAncestors.length - 1 && (
                    <Typography 
                      sx={{ 
                        fontSize: '0.7rem', 
                        color: '#666', 
                        ml: 0.5 
                      }}
                    >
                      {'›'}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>

          <Box
            sx={{
              backgroundColor: "#d1d5db",
              p: 1,
              borderRadius: "10px",
              mt: 1,
              position: "relative",
              zIndex: 1,
              height: "40px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: `${task?.progress || 0}%`,
                backgroundColor: "white",
                height: "100%",
                borderRadius: "5px",
                backgroundColor: getProgressColor(task?.progress)
                  ?.backgroundColor,
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
              }}
            ></Box>
            <Box
              sx={{
                width: "100%",
                zIndex: 2,
              }}
            >
              <BoxSpaceBetween>
                <HorizBox>
                  <Typography
                    variant="h6"
                    sx={{
                      color: getProgressColor(task?.progress)?.color,
                    }}
                  >
                    {task?.progress || 0}%
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: getProgressColor(task?.progress)?.color,
                    }}
                  >
                    Progress
                  </Typography>
                </HorizBox>
                <Box>
                  <Button
                    sx={{
                      backgroundColor: "white",
                      color: getProgressColor(task?.progress)?.color,
                      width: "100px",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowUpdateTaskDialog(true);
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </BoxSpaceBetween>
            </Box>
          </Box>
        </Box>
      </Box>
      <TaskProgressUpdateDrawer
        open={showUpdateTaskDialog}
        setOpen={setShowUpdateTaskDialog}
        setTasks={setTasks}
        setTask={setTask}
        taskToUpdate={task}
      />
      <AddTaskDialog
        assigneesByCreater={task?.assigned}
        showAddTaskDialog={showEditTaskDialog}
        setShowAddTaskDialog={setShowEditTaskDialog}
        targetId={task?.target?._id}
        onTaskUpdate={(newTask) => {
          const index = tasks?.findIndex((item) => item._id === newTask._id);
          const prevTask = tasks;
          if (index > -1) {
            prevTask[index] = newTask;
            setTasks(prevTask);
          }

        }}
        onTaskDelete={(deletedTask) => {
          const prevTask = tasks;
          setTasks(prevTask?.filter((item) => item._id !== deletedTask._id));
        }}
        profileId={task?.target?.organization?.profile}
        library={task?.target?.buildingCodeLibrary}
        project={task?.target?.project}
        organization={task?.target?.organization}
        taskToUpdate={taskToUpdate}
      />
    </>
  );
};

export default TaskCard;