import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, IconButton, Dialog, DialogTitle, CircularProgress, DialogContent, DialogActions, Stack, Popover, Card, CardContent, TextField, InputAdornment } from '@mui/material';
import { VariableSizeList as List } from 'react-window';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Api from '../../../helpers/Api';
import moment from 'moment';
import AddManyTasks from './AddManyTasks';
import AddIcon from '@mui/icons-material/Add';
import DelayTracker from './DelayTracker';
import TimerIcon from '@mui/icons-material/Timer';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
  },
  contentWrapper: {
    display: 'inline-block',
    minWidth: '100%',
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px',
    borderBottom: '2px solid #e0e0e0',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    '& > *': {
      borderRight: '1px solid #e0e0e0',
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  headerActions: {
    width: '100px',
    minWidth: '100px',
    position: 'sticky',
    right: 0,
    backgroundColor: '#f8f9fa',
    borderLeft: '1px solid #e0e0e0',
    justifyContent: 'center',
    zIndex: 3,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderBottom: '1px solid #e0e0e0',
    position: 'relative',
    fontSize: '0.8em',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '& > *': {
      borderRight: '1px solid #e0e0e0',
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  taskId: {
    width: '80px',
    minWidth: '80px',
  },
  taskName: {
    flex: 2,
    minWidth: '400px',
  },
  progress: {
    width: '100px',
    minWidth: '100px',
    justifyContent: 'flex-end',
  },
  cumulativeProgress: {
    width: '120px',
    minWidth: '120px',
    justifyContent: 'flex-end',
  },
  delay: {
    width: '120px',
    minWidth: '120px',
    justifyContent: 'flex-end',
  },
  duration: {
    width: '120px',
    minWidth: '120px',
    justifyContent: 'flex-end',
  },
  date: {
    width: '120px',
    minWidth: '120px',
    justifyContent: 'center',
  },
  predecessors: {
    width: '150px',
    minWidth: '150px',
  },
  actions: {
    width: '100px',
    minWidth: '100px',
    position: 'sticky',
    right: 0,
    backgroundColor: 'white',
    borderLeft: '1px solid #e0e0e0',
    justifyContent: 'center',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  loadingMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    fontWeight: 500,
    '& .emoji': {
      fontSize: '20px'
    }
  },
  blueText: {
    color: '#2196f3'
  },
  orangeText: {
    color: '#ff9800'
  },
  greenText: {
    color: '#4caf50'
  },
  purpleText: {
    color: '#9c27b0'
  },
  negativeDelay: {
    color: '#d32f2f',
  },
}));



const TargetAnalytics = () => {
  const { targetId } = useParams();
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [expandedTasks, setExpandedTasks] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [addTasksDrawerOpen, setAddTasksDrawerOpen] = useState(false);
  const [selectedTaskForAdd, setSelectedTaskForAdd] = useState(null);
  const [delayTrackerOpen, setDelayTrackerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    minProgress: '',
    maxProgress: '',
  });

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setLoadingMessage('This takes about a minute for 2500 tasks');
        const messageTimer = setInterval(() => {
          setLoadingMessage(current => {
            switch(current) {
              case 'This takes about a minute for 2500 tasks':
                return 'Getting tasks';
              case 'Getting tasks':
                return 'Getting parent child relationships';
              case 'Getting parent child relationships':
                return 'Calculating cumulative completion percentage';
              default:
                return current;
            }
          });
        }, 7000);

        const { data } = await Api.post('wbs/targetAnalytics', { targetId });
        setTasks(data);

        clearInterval(messageTimer);
        setLoadingMessage('');
      } catch (error) {
        console.error('Failed to fetch analytics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [targetId]);


  const getLoadingMessageStyle = (message) => {
    switch(message) {
      case 'This takes about a minute for 2500 tasks':
        return classes.blueText;
      case 'Getting tasks':
        return classes.orangeText;
      case 'Getting parent child relationships':
        return classes.greenText;
      case 'Calculating cumulative completion percentage':
        return classes.purpleText;
      default:
        return '';
    }
  };
  
  const getLoadingMessageEmoji = (message) => {
    switch(message) {
      case 'This takes about a minute for 2500 tasks':
        return '⏳';
      case 'Getting tasks':
        return '📋';
      case 'Getting parent child relationships':
        return '🔄';
      case 'Calculating cumulative completion percentage':
        return '📊';
      default:
        return '';
    }
  };

  const toggleExpand = (taskId) => {
    setExpandedTasks(prev => ({...prev, [taskId]: !prev[taskId]}));
  };

  // Build the flattened tree structure with depth information
  const items = useMemo(() => {
    const taskMap = {};
    tasks.forEach((task) => {
      task.children = [];
      taskMap[task._id] = task;
    });

    const roots = [];
    tasks.forEach((task) => {
      if (task.parentTask && taskMap[task.parentTask]) {
        taskMap[task.parentTask].children.push(task);
      } else {
        roots.push(task);
      }
    });

    const flatten = (nodes, depth = 0) => {
      let result = [];
      nodes.forEach((node) => {
        result.push({ ...node, depth });
        if (node.children.length > 0) {
          result = result.concat(flatten(node.children, depth + 1));
        }
      });
      return result;
    };

    return flatten(roots);
  }, [tasks]);

  const filteredItems = useMemo(() => {
    // First, find all tasks that match the search term
    const matchingTaskIds = searchTerm 
      ? tasks.filter(task => task.taskName.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(task => task._id)
      : [];

    return items.filter(item => {
      // If there's a search term, show the matching tasks and their ancestors
      if (searchTerm) {
        const isMatch = matchingTaskIds.includes(item._id);
        const hasMatchingDescendant = matchingTaskIds.some(id => 
          tasks.find(t => t._id === id)?.ancestors?.includes(item._id)
        );
        if (!isMatch && !hasMatchingDescendant) return false;
      }

      // Apply other filters
      if (filters.startDate && moment(item.ps).isBefore(filters.startDate)) return false;
      if (filters.endDate && moment(item.pf).isAfter(filters.endDate)) return false;
      if (filters.minProgress && item.progress < parseInt(filters.minProgress)) return false;
      if (filters.maxProgress && item.progress > parseInt(filters.maxProgress)) return false;

      return true;
    });
  }, [items, searchTerm, filters, tasks]);

  const visibleItems = useMemo(() => {
    return filteredItems.filter(item => {
      if (item.depth === 0) return true;
      let parent = items.find(i => i._id === item.parentTask);
      while (parent) {
        if (!expandedTasks[parent._id]) return false;
        parent = items.find(i => i._id === parent.parentTask);
      }
      return true;
    });
  }, [filteredItems, expandedTasks, items]);

  const getItemSize = () => 50;

  const handleDialogOpen = (task) => {
    setSelectedTask(task);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedTask(null);
  };

  const handleDeleteTask = async (deleteChildren) => {
    try {
      await Api.post('wbs/deleteTask', {
        taskId: selectedTask._id,
        deleteChildren
      });

      // Update local state instead of reloading
      if (deleteChildren) {
        // Remove the task and all its descendants
        setTasks(prevTasks => prevTasks.filter(task => {
          const isDescendant = task.ancestors?.includes(selectedTask._id);
          const isSelectedTask = task._id === selectedTask._id;
          return !isDescendant && !isSelectedTask;
        }));
      } else {
        // Remove only the selected task and update its children
        setTasks(prevTasks => {
          // First, get all tasks except the one being deleted
          const remainingTasks = prevTasks.filter(task => task._id !== selectedTask._id);
          
          // Then update the children of the deleted task
          return remainingTasks.map(task => {
            if (task.parentTask === selectedTask._id) {
              return {
                ...task,
                parentTask: selectedTask.parentTask,
                ancestors: (task.ancestors || []).filter(id => id !== selectedTask._id)
              };
            }
            return task;
          });
        });
      }

      // Clear expanded state for deleted task
      setExpandedTasks(prev => {
        const newState = { ...prev };
        delete newState[selectedTask._id];
        return newState;
      });

    } catch (error) {
      console.error('Failed to delete task:', error);
    }
    handleDialogClose();
  };

  const calculateDelay = (task, currentDate = new Date()) => {
    // For tasks with children, return max delay of children
    if (task.children && task.children.length > 0) {
      return Math.max(...task.children.map(child => calculateDelay(child, currentDate)));
    }

    // For leaf tasks
    const plannedStart = new Date(task.ps);
    const plannedFinish = new Date(task.pf);
    
    // If task hasn't started yet
    if (task.progress === 0 && currentDate > plannedStart) {
      return Math.ceil((currentDate - plannedStart) / (1000 * 60 * 60 * 24));
    }

    // If task is complete
    if (task.progress === 100) {
      // Special case: if startDate equals endDate, task was completed without tracking
      if (!task.startDate || !task.endDate || task.startDate === task.endDate) {
        return 0;
      }
      const actualEnd = new Date(task.endDate || currentDate);
      return Math.ceil((actualEnd - plannedFinish) / (1000 * 60 * 60 * 24));
    }
    
    // If task is in progress
    if (task.progress > 0) {
      const startDate = new Date(task.startDate || plannedStart);
      if (task.progress < 10) {
        return Math.ceil((startDate - plannedStart) / (1000 * 60 * 60 * 24));
      } else {
        // Calculate estimated completion date
        const daysSpent = (currentDate - startDate) / (1000 * 60 * 60 * 24);
        const totalEstimatedDays = daysSpent * (100 / task.progress);
        const estimatedCompleteDate = new Date(startDate.getTime() + totalEstimatedDays * 24 * 60 * 60 * 1000);
        return Math.ceil((estimatedCompleteDate - plannedFinish) / (1000 * 60 * 60 * 24));
      }
    }
    
    return 0;
  };

  const TableHeader = () => (
    <Box className={classes.header}>
      <Typography className={classes.taskId}>ID</Typography>
      <Typography className={classes.taskName}>Task Name</Typography>
      <Typography className={classes.progress}>Progress</Typography>
      <Typography className={classes.cumulativeProgress}>Cumulative</Typography>
      <Typography className={classes.delay}>Delay (days)</Typography>
      <Typography className={classes.duration}>Duration (days)</Typography>
      <Typography className={classes.date}>Planned Start</Typography>
      <Typography className={classes.date}>Planned Finish</Typography>
      <Typography className={classes.date}>Start Date</Typography>
      <Typography className={classes.date}>Finish Date</Typography>
      <Typography className={classes.predecessors}>Predecessors</Typography>
      <Typography className={classes.headerActions}>Actions</Typography>
    </Box>
  );

  const Row = ({ index, style }) => {
    const item = visibleItems[index];
    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedTasks[item._id];
    const delay = calculateDelay(item);

    return (
      <Box style={style} className={classes.row}>
        <Typography className={classes.taskId}>{item.mspId || ''}</Typography>
        <Box className={classes.taskName} style={{ paddingLeft: item.depth * 20 }}>
          {hasChildren && (
            <IconButton size="small" onClick={() => toggleExpand(item._id)}>
              {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
          <Typography>{item.taskName}</Typography>
        </Box>
        <Typography className={classes.progress}>{item.progress}%</Typography>
        <Typography className={classes.cumulativeProgress}>{item.c_p}%</Typography>
        <Typography className={`${classes.delay} ${delay > 0 ? classes.negativeDelay : ''}`}>
          {delay}
        </Typography>
        <Typography className={classes.delay}>{item.duration}</Typography>
        <Typography className={classes.date}>
          {moment(item.ps).format('DD/MM/YYYY')}
        </Typography>
        <Typography className={classes.date}>
          {moment(item.pf).format('DD/MM/YYYY')}
        </Typography>
        <Typography className={classes.date}>
          {item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : '-'}
        </Typography>
        <Typography className={classes.date}>
          {item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : '-'}
        </Typography>
        <Typography className={classes.predecessors}>
          {item.predecessorMap?.map(pred => {
            let str = pred.mspId;
            if (pred.relationType) str += pred.relationType;
            if (pred.lag) str += pred.lag;
            return str;
          }).join(', ') || ''}
        </Typography>
        <Box className={classes.actions}>
          <IconButton
            size="small"
            onClick={() => window.open(`/planning/task/${item._id}`, '_blank')}
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleDialogOpen(item)}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const clearFilters = () => {
    setSearchTerm('');
    setFilters({
      startDate: null,
      endDate: null,
      minProgress: '',
      maxProgress: '',
    });
  };

  const renderFilterPopover = () => (
    <Popover
      open={Boolean(filterAnchorEl)}
      anchorEl={filterAnchorEl}
      onClose={() => setFilterAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card sx={{ minWidth: 300 }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="subtitle2">Filters</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                value={filters.startDate}
                onChange={(date) => setFilters(prev => ({ ...prev, startDate: date }))}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              <DatePicker
                label="End Date"
                value={filters.endDate}
                onChange={(date) => setFilters(prev => ({ ...prev, endDate: date }))}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
            <TextField
              label="Minimum Progress"
              value={filters.minProgress}
              onChange={(e) => setFilters(prev => ({ ...prev, minProgress: e.target.value }))}
              type="number"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
            <TextField
              label="Maximum Progress"
              value={filters.maxProgress}
              onChange={(e) => setFilters(prev => ({ ...prev, maxProgress: e.target.value }))}
              type="number"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
            <Button
              variant="outlined"
              onClick={clearFilters}
              startIcon={<ClearIcon />}
            >
              Clear Filters
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Popover>
  );

  return (
    <Box className={classes.container}>
      <Box sx={{ mb: 2, p: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            placeholder="Search tasks..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            sx={{ width: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setSearchTerm('')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setAddTasksDrawerOpen(true)}
          >
            Add Multiple Tasks
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<TimerIcon />}
            onClick={() => setDelayTrackerOpen(true)}
          >
            Delay Tracker
          </Button>
          <IconButton onClick={(e) => setFilterAnchorEl(e.currentTarget)}>
            <FilterListIcon />
          </IconButton>
        </Stack>
      </Box>

      {loading ? (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <CircularProgress />
          {loadingMessage && (
            <Typography 
              className={`${classes.loadingMessage} ${getLoadingMessageStyle(loadingMessage)}`}
            >
              <span className="emoji">{getLoadingMessageEmoji(loadingMessage)}</span>
              {loadingMessage}
            </Typography>
          )}
        </Box>
      ) : (
        <Box className={classes.tableContainer}>
          <Box className={classes.contentWrapper}>
            <TableHeader />
            <List
              height={600}
              width="100%"
              itemCount={visibleItems.length}
              itemSize={getItemSize}
              overscanCount={5}
            >
              {Row}
            </List>
          </Box>
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {selectedTask ? `Task: ${selectedTask.taskName}` : ''}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="subtitle1" color="text.secondary">
              Choose action:
            </Typography>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              onClick={() => {
                setAddTasksDrawerOpen(true);
                setSelectedTaskForAdd(selectedTask);
                handleDialogClose();
              }}
            >
              Add subtasks
            </Button>
            <Button
              startIcon={<DeleteOutlineIcon />}
              variant="outlined"
              color="error"
              onClick={() => handleDeleteTask(false)}
            >
              Delete this task only
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant="contained"
              color="error"
              onClick={() => handleDeleteTask(true)}
            >
              Delete this task and all children
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <AddManyTasks
        open={addTasksDrawerOpen}
        onClose={(saved) => {
          setAddTasksDrawerOpen(false);
          setSelectedTaskForAdd(null);
          if (saved) {
            // Refresh the task list
            fetchTasks();
          }
        }}
        targetId={targetId}
        parentTask={selectedTaskForAdd}
      />

      <DelayTracker
        open={delayTrackerOpen}
        onClose={() => setDelayTrackerOpen(false)}
        tasks={tasks}
        calculateDelay={calculateDelay}
      />
      {renderFilterPopover()}
    </Box>
  );
};

export default TargetAnalytics;

