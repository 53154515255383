import React, { useState, useMemo } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  Popover,
  Card,
  CardContent,
  InputAdornment,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from 'moment';

const DelayTracker = ({ open, onClose, tasks, calculateDelay }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDelay, setMinDelay] = useState('');
  const [maxDelay, setMaxDelay] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  // Create a task map for looking up task details
  const taskMap = useMemo(() => {
    const map = {};
    tasks.forEach(task => {
      map[task._id] = task;
    });
    return map;
  }, [tasks]);

  // Get ancestor names for a task
  const getAncestorNames = (task) => {
    const names = [];
    let currentTask = task;
    
    while (currentTask.parentTask && taskMap[currentTask.parentTask]) {
      currentTask = taskMap[currentTask.parentTask];
      names.unshift(currentTask.taskName);
    }
    
    return names;
  };

  const filteredTasks = useMemo(() => {
    return tasks
      .filter(task => {
        // Only include leaf tasks (tasks with no children)
        if (task.children && task.children.length > 0) return false;

        // Calculate delay using the passed calculateDelay function
        const taskDelay = calculateDelay(task);
        if (taskDelay <= 0) return false;

        // Apply date filter if set
        if (startDate && moment(task.ps).isBefore(startDate)) return false;
        if (endDate && moment(task.pf).isAfter(endDate)) return false;

        // Apply minimum delay filter if set
        if (minDelay && taskDelay < parseInt(minDelay)) return false;
        
        // Apply maximum delay filter if set
        if (maxDelay && taskDelay > parseInt(maxDelay)) return false;

        return true;
      })
      .sort((a, b) => calculateDelay(b) - calculateDelay(a))
      .map(task => ({
        ...task,
        ancestorNames: getAncestorNames(task)
      }));
  }, [tasks, startDate, endDate, minDelay, maxDelay, calculateDelay, taskMap]);

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setMinDelay('');
    setMaxDelay('');
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '600px',
          p: 2
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Delay Tracker</Typography>
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card sx={{ minWidth: 300 }}>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="subtitle2">Filters</Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={setStartDate}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={setEndDate}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
              </LocalizationProvider>
              <TextField
                label="Minimum Delay (days)"
                value={minDelay}
                onChange={(e) => setMinDelay(e.target.value)}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
              />
              <TextField
                label="Maximum Delay (days)"
                value={maxDelay}
                onChange={(e) => setMaxDelay(e.target.value)}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
              />
              <Button
                variant="outlined"
                onClick={clearFilters}
                startIcon={<ClearIcon />}
              >
                Clear Filters
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Popover>

      <List>
        {filteredTasks.map((task) => (
          <ListItem
            key={task._id}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            secondaryAction={
              <IconButton
                edge="end"
                size="small"
                onClick={() => window.open(`/planning/task/${task._id}`, '_blank')}
              >
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            }
          >
            <ListItemText
              primary={
                <Box sx={{ pr: 4 }}>
                  {task.taskName}
                </Box>
              }
              secondary={
                <>
                  {task.ancestorNames.length > 0 && (
                    <Typography 
                      variant="caption" 
                      display="block" 
                      color="text.secondary"
                      sx={{ 
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        pr: 4 
                      }}
                    >
                      {task.ancestorNames.join(' > ')}
                    </Typography>
                  )}
                  <Typography 
                    component="span" 
                    sx={{ 
                      color: 'error.main', 
                      fontWeight: 'bold',
                      display: 'block'
                    }}
                  >
                    Delay: {calculateDelay(task)} days
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
        {filteredTasks.length === 0 && (
          <ListItem>
            <ListItemText
              primary="No delayed tasks found"
              secondary="Try adjusting your filters"
            />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default DelayTracker;
