import React, { useState } from 'react';
import { 
  Drawer, Box, Typography, Button, IconButton, TextField, 
  Stack, Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem
} from '@mui/material';
import DatePicker from '../../styled/generic/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import Api from '../../../helpers/Api';

const TableHeader = () => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    p: 1,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    bgcolor: '#f5f5f5'
  }}>
    <Typography sx={{ width: 80, mr: 1 }}>MSP ID</Typography>
    <Typography sx={{ flex: 1, mr: 1 }}>Task Name</Typography>
    <Typography sx={{ width: 100, mr: 1 }}>Progress</Typography>
    <Typography sx={{ width: 150, mr: 1 }}>Start Date</Typography>
    <Typography sx={{ width: 150, mr: 1 }}>End Date</Typography>
    <Typography sx={{ width: 120, mr: 1 }}>Predecessors</Typography>
    <Typography sx={{ width: 100 }}>Actions</Typography>
  </Box>
);

const TaskRow = ({ task, onUpdate, onDelete, onAddChild, onAddPredecessor, depth = 0 }) => {
  const predecessorsString = task.predecessorMap?.map(pred => {
    let str = pred.mspId;
    if (pred.relationType) str += pred.relationType;
    if (pred.lag) str += pred.lag;
    return str;
  }).join(',') || '';

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'flex-start',
      p: 1,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    }}>
      <TextField
        size="small"
        value={task.mspId}
        onChange={(e) => {
          const value = e.target.value;
          if (value === '' || /^\d+$/.test(value)) {
            onUpdate({ ...task, mspId: value });
          }
        }}
        placeholder="ID"
        type="number"
        sx={{ width: 80, mr: 1 }}
      />
      <TextField
        size="small"
        value={task.taskName}
        onChange={(e) => onUpdate({ ...task, taskName: e.target.value })}
        placeholder="Task name"
        multiline
        minRows={1}
        maxRows={3}
        sx={{ 
          flex: 1,
          mr: 1,
          '& .MuiInputBase-input': {
            paddingLeft: `${depth * 20}px`
          }
        }}
      />
      <TextField
        size="small"
        type="number"
        value={task.progress}
        onChange={(e) => {
          const value = Number(e.target.value);
          if (value >= 0 && value <= 100) {
            onUpdate({ ...task, progress: value });
          }
        }}
        inputProps={{ 
          min: 0, 
          max: 100,
          step: 1
        }}
        placeholder="Progress"
        sx={{ width: 100, mr: 1 }}
      />
      <DatePicker
        value={task.ps}
        onChange={(date) => onUpdate({ ...task, ps: date })}
        textfieldProps={{ 
          size: "small",
          sx: { mr: 1, width: '150px' }
        }}
        fullWidth={false}
      />
      <DatePicker
        value={task.pf}
        onChange={(date) => onUpdate({ ...task, pf: date })}
        textfieldProps={{ 
          size: "small",
          sx: { mr: 1, width: '150px' }
        }}
        fullWidth={false}
      />
      <Box sx={{ width: 120, mr: 1, display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ flex: 1, fontSize: '0.75rem' }}>
          {predecessorsString}
        </Typography>
        <IconButton 
          size="small"
          onClick={() => onAddPredecessor(task.mspId)}
          sx={{ ml: 1 }}
        >
          <LinkIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ width: 100, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => onAddChild(task.mspId)}>
          <AddIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(task.mspId)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const PredecessorDialog = ({ open, onClose, onSave, task }) => {
  const [predecessors, setPredecessors] = useState([{
    mspId: '',
    lag: '0',
    relationType: 'FS',
    wbsId: null
  }]);

  const handleAddRow = () => {
    setPredecessors([...predecessors, {
      mspId: '',
      lag: '0',
      relationType: 'FS',
      wbsId: null
    }]);
  };

  const handleRemoveRow = (index) => {
    setPredecessors(prev => prev.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    if (field === 'mspId') {
      // Only allow numbers or empty string and prevent using current task's mspId
      if ((value === '' || /^\d+$/.test(value)) && value !== task?.mspId) {
        setPredecessors(prev => prev.map((pred, i) => 
          i === index ? { ...pred, [field]: value } : pred
        ));
      }
      return;
    }

    setPredecessors(prev => prev.map((pred, i) => 
      i === index ? { ...pred, [field]: value } : pred
    ));
  };

  const handleSave = () => {
    // Filter out any incomplete rows
    const validPredecessors = predecessors.filter(p => p.mspId && p.relationType);
    onSave(validPredecessors);
    setPredecessors([{ mspId: '', lag: '0', relationType: 'FS', wbsId: null }]);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Add Predecessors for Task {task?.mspId} - {task?.taskName}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {/* Header */}
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr 1fr 1fr 50px',
            gap: 2,
            mb: 1,
            fontWeight: 'bold'
          }}>
            <Typography>MSP ID</Typography>
            <Typography>Lag (days)</Typography>
            <Typography>Relation Type</Typography>
            <Typography></Typography>
          </Box>

          {/* Predecessor Rows */}
          {predecessors.map((pred, index) => (
            <Box 
              key={index}
              sx={{ 
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 50px',
                gap: 2,
                mb: 2,
                alignItems: 'center'
              }}
            >
              <TextField
                size="small"
                value={pred.mspId}
                onChange={(e) => handleChange(index, 'mspId', e.target.value)}
                placeholder="Enter MSP ID"
                type="text"
                inputProps={{ pattern: "\\d*" }}
                error={pred.mspId === task?.mspId}
                helperText={pred.mspId === task?.mspId ? "Cannot link to self" : ""}
                fullWidth
              />

              <TextField
                size="small"
                type="number"
                value={pred.lag}
                onChange={(e) => handleChange(index, 'lag', e.target.value)}
                fullWidth
              />

              <Select
                size="small"
                value={pred.relationType}
                onChange={(e) => handleChange(index, 'relationType', e.target.value)}
                fullWidth
              >
                <MenuItem value="FF">Finish to Finish (FF)</MenuItem>
                <MenuItem value="FS">Finish to Start (FS)</MenuItem>
                <MenuItem value="SS">Start to Start (SS)</MenuItem>
                <MenuItem value="SF">Start to Finish (SF)</MenuItem>
              </Select>

              <IconButton 
                onClick={() => handleRemoveRow(index)}
                disabled={predecessors.length === 1}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          {/* Add Row Button */}
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddRow}
            variant="outlined"
            sx={{ mt: 1 }}
          >
            Add Predecessor
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave} 
          variant="contained"
          disabled={!predecessors.some(p => p.mspId && p.relationType)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddManyTasks = ({ open, onClose, targetId, parentTask = null }) => {
  const initialState = [{
    mspId: '1',
    taskName: '',
    progress: 0,
    ps: null,
    pf: null,
    parentId: null,
    depth: 0,
    predecessorMap: []
  }];

  const [tasks, setTasks] = useState(initialState);
  const [predecessorDialogOpen, setPredecessorDialogOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const getNextId = () => {
    const maxId = Math.max(...tasks.map(t => Number(t.mspId) || 0));
    return String(maxId + 1);
  };

  const handleClose = (saved = false) => {
    setTasks(initialState);
    onClose(saved);
  };

  const hasDuplicateMspIds = () => {
    const mspIds = tasks.map(t => t.mspId);
    console.log(mspIds,' are the mspIds')
    return new Set(mspIds).size !== mspIds.length;
  };

  const handleAddChild = (parentId) => {
    const newId = getNextId();
    setTasks(prev => {
      // Find the parent task's index
      const parentIndex = prev.findIndex(t => t.mspId === parentId);
      
      // If parent not found, don't add child
      if (parentIndex === -1) {
        console.error('Parent task not found:', parentId);
        return prev;
      }
      
      // Find the last child of this parent (if any)
      let insertIndex = parentIndex + 1;
      const parentDepth = prev[parentIndex].depth;
      
      while (
        insertIndex < prev.length && 
        prev[insertIndex] && 
        prev[insertIndex].depth > parentDepth
      ) {
        insertIndex++;
      }

      const newTask = {
        mspId: newId,
        taskName: '',
        progress: 0,
        ps: null,
        pf: null,
        parentId,
        depth: parentDepth + 1
      };

      // Insert the new task after the last child
      return [
        ...prev.slice(0, insertIndex),
        newTask,
        ...prev.slice(insertIndex)
      ];
    });
  };

  const handleAddRootTask = () => {
    const newId = getNextId();
    setTasks(prev => [...prev, {
      mspId: newId,
      taskName: '',
      progress: 0,
      ps: null,
      pf: null,
      parentId: null,
      depth: 0
    }]);
  };

  const handleAddPredecessor = (taskId) => {
    const selectedTask = tasks.find(t => t.mspId === taskId);
    setSelectedTaskId(taskId);
    setPredecessorDialogOpen(true);
  };

  const handleSavePredecessor = async (predecessorData) => {
    setTasks(prev => prev.map(task => {
      if (task.mspId === selectedTaskId) {
        return {
          ...task,
          predecessorMap: [...(task.predecessorMap || []), predecessorData]
        };
      }
      return task;
    }));
  };

  const handleSave = async () => {
    try {
      // First get WBS IDs for MSP IDs
      const allMspIds = [...new Set(tasks.flatMap(task => [
        task.mspId,
        ...(task.predecessorMap || []).map(p => p.mspId)
      ]))];

      const { data: { idMapping } } = await Api.post('wbs/get-by-msp-ids', {
        mspIds: allMspIds
      });

      const formattedTasks = tasks.map(task => ({
        taskName: task.taskName,
        progress: Number(task.progress),
        ps: task.ps,
        pf: task.pf,
        parentMSPId: task.parentId !== null ? String(task.parentId) : undefined,
        mspId: String(task.mspId),
        predecessorMap: (task.predecessorMap || []).map(pred => ({
          ...pred,
          wbs: idMapping[pred.mspId] || undefined
        })),
        predecessors: (task.predecessorMap || [])
          .map(pred => idMapping[pred.mspId])
          .filter(Boolean)
      }));

      await Api.post('wbs/addManyChildren', {
        parentTaskId: parentTask?._id || targetId,
        tasks: formattedTasks
      });

      handleClose(true);
    } catch (error) {
      console.error('Failed to save tasks:', error);
    }
  };

  return (
    <Drawer 
      anchor="right" 
      open={open} 
      onClose={() => handleClose(false)}
      PaperProps={{
        sx: { width: '90vw' }
      }}
    >
      <Box sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{ 
          p: 3, 
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h6">
            Add Multiple Tasks {parentTask ? `to ${parentTask.taskName}` : ''}
          </Typography>
          <IconButton 
            onClick={() => handleClose(false)}
            size="small"
            sx={{ ml: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <TableHeader />
          <Stack>
            {tasks.map((task, index) => (
              <TaskRow
                key={index}
                task={task}
                depth={task.depth}
                onUpdate={(updatedTask) => {
                  setTasks(prev => prev.map((t, i) => 
                    i === index ? updatedTask : t
                  ));
                }}
                onDelete={(taskId) => {
                  setTasks(prev => prev.filter((t, i) => i !== index));
                }}
                onAddChild={handleAddChild}
                onAddPredecessor={handleAddPredecessor}
              />
            ))}
          </Stack>
          <Box sx={{ p: 2 }}>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddRootTask}
              variant="outlined"
            >
              Add Root Task
            </Button>
          </Box>
        </Box>

        <Box sx={{ 
          p: 2, 
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          {hasDuplicateMspIds() && (
            <Typography color="error" sx={{ mr: 2 }}>
              MSP IDs must be unique
            </Typography>
          )}
          <Button 
            variant="contained" 
            onClick={handleSave}
            disabled={
              tasks.some(t => !t.taskName || !t.ps || !t.pf) || 
              hasDuplicateMspIds()
            }
          >
            Save Tasks
          </Button>
        </Box>
        <PredecessorDialog
          open={predecessorDialogOpen}
          onClose={() => setPredecessorDialogOpen(false)}
          onSave={handleSavePredecessor}
          task={tasks.find(t => t.mspId === selectedTaskId)}
        />
      </Box>
    </Drawer>
  );
};

export default AddManyTasks;
